<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="container-fluid">
          <!-- Default box -->
          <section class="content-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="mb-0">Liquidaciones</h5>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/Hse">Comercialización</router-link>
                    </li>
                    <li class="breadcrumb-item active">
                      Liquidaciones
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="container-fluid">
              <div class="card">
                <div class="card-header pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-info"
                          @click="back()"
                        >
                          <i class="fas fa-sync-alt"></i>
                        </button>
                        <button
                          v-if="$store.getters.can('com.liquidacion.create')"
                          type="button"
                          class="btn btn-primary"
                          @click="facturacionForm('Crear', null)"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table
                    style="font-size: 0.85em"
                    class="table table-bordered table-striped table-hover table-sm text-nowrap"
                  >
                    <thead class="text-center">
                      <tr>
                        <th>
                          Id
                          <input
                            type="number"
                            class="form-control form-control-sm p-0"
                            v-model="filters.id"
                            label="id"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Cliente
                          <v-select
                            class="form-control form-control-sm p-0"
                            v-model="cliente"
                            placeholder="Clientes"
                            label="razon_social"
                            :options="listasForms.clientes"
                            @input="getIndex()"
                          >
                          </v-select>
                        </th>
                        <th>
                          Fecha Inicial
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filters.fecha_ini"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Fecha Final
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filters.fecha_fin"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Estado
                          <v-select
                            class="form-control form-control-sm p-0"
                            v-model="estado"
                            placeholder="Estados"
                            label="descripcion"
                            :options="listasForms.estados"
                            @input="getIndex()"
                          >
                          </v-select>
                        </th>
                        <th
                          v-if="
                            $store.getters.can('com.liquidacion.edit') ||
                              $store.getters.can('com.liquidacion.delete')
                          "
                        >
                          acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="facturacion in facturaciones.data"
                        :key="facturacion.data"
                      >
                        <td class="text-center">{{ facturacion.id }}</td>
                        <td>
                          {{
                            facturacion.cliente
                              ? facturacion.cliente.razon_social
                              : ""
                          }}
                        </td>
                        <td class="text-center">{{ facturacion.fecha_ini }}</td>
                        <td class="text-center">
                          {{ facturacion.fecha_fin }}
                        </td>
                        <td>{{ facturacion.Estado }}</td>
                        <td
                          v-if="
                            $store.getters.can('com.liquidacion.edit') ||
                              $store.getters.can('com.liquidacion.delete')
                          "
                        >
                          <div class="btn-group float-right">
                            <button
                              v-if="$store.getters.can('com.liquidacion.edit')"
                              type="button"
                              class="btn btn-sm bg-navy"
                              @click="facturacionForm('Editar', facturacion)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              v-if="
                                $store.getters.can('com.liquidacion.delete')
                              "
                              type="button"
                              class="btn btn-sm btn-danger"
                              @click="eliminar(facturacion.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination
                  :data="facturaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "FacturacionesIndex",
  components: {
    vSelect,
    pagination,
    Loading,
  },
  data() {
    return {
      cargando: false,
      facturaciones: {},
      estado: [],
      cliente: [],
      listasForms: {
        clientes: [],
        estados: [],
      },
      filters: {
        id: null,
        fecha_ini: null,
        fecha_fin: null,
        cliente_id: null,
        estado: null,
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      this.filters.cliente_id = this.cliente ? this.cliente.id : null;
      this.filters.estado = this.estado ? this.estado.numeracion : null;
      this.cargando = true;
      axios
        .get("api/com/facturacion?page=" + page, {
          params: this.filters,
        })
        .then((response) => {
          this.cargando = false;
          this.facturaciones = response.data;
        });
    },

    back() {
      this.clearFilters();
      this.getIndex();
    },

    clearFilters() {
      this.filters = {
        solicitud_id: null,
        nombre: null,
        email: null,
        tipo_documento_id: null,
        tipo_contrato_id: null,
        documento: null,
        empresa_id: null,
        activo_id: null,
        estado: null,
      };
      this.tipo_documento = null;
      this.empresa = null;
      this.activo = null;
      this.tipo_contrato = null;
    },

    facturacionForm(accion, facturacion) {
      if (accion == "Editar") {
        return this.$router.push({
          name: "/Com/LiquidacionForm",
          params: {
            accion: "Editar",
            data_edit: facturacion,
            id: facturacion.id,
          },
        });
      } else {
        return this.$router.push({
          name: "/Com/LiquidacionForm",
          params: {
            accion: accion,
          },
        });
      }
    },
    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getClientes() {
      axios.get("/api/com/clientes").then((response) => {
        this.listasForms.clientes = response.data.data;
      });
    },
    eliminar(id) {
      axios
        .delete("api/com/facturacion/" + id)
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Facturación eliminada correctamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.back();
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getClientes();
  },
};
</script>
